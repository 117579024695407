<template>
  <v-footer
    color="#febd3b"
    app flat
    min-height="64px"
    :absolute="true "
  >

    <div>
      <a
        :href="'/static/lk/docs/Правила_партнерской_программы.pdf'"
        target="_blank" class="doc-link"
      >Правила партнерской программы
      </a>
      <br/>
      <a
        :href="'/static/lk/docs/Соглашение_об_использовании_АСП.pdf'"
        target="_blank" class="doc-link"
      >Соглашение об использовании АСП
      </a>
    </div>
    <v-spacer/>
    <div>
      <a :href="'tel:88005505235'" style="text-decoration: none; color: inherit;">
        <b>8 (800) 550-52-35</b>
      </a>
      <span> звонки по всей России</span>
    </div>
  </v-footer>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "AppFooter",
  computed: {
    ...mapState(['agent']),
  }
}
</script>

<style scoped>
.doc-link {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}
.doc-link:hover {
  text-decoration: underline;
}
</style>