<template>
  <v-app-bar
    color="#febd3b"
    app flat
    height="80px"
  >
    <a href="/">
      <div
        class="app-bar-logo"
        :style="{'background-image': 'url(/static/lk/img/logo.svg)'}"
      />
    </a>
    <v-spacer/>
    <template v-if="agent">
      <v-btn
        depressed style="color: #febd3b"
        href="/logout/"
      >
        Выход
      </v-btn>
    </template>
    <template v-else>
      <v-btn
        depressed style="color: #febd3b"
        href="/login/"
      >
        Вход
      </v-btn>
      <v-btn
        class="ml-4"
        depressed style="color: #febd3b"
        href="/registration/"
      >
        Стать партнером
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "AppBar",
  computed: {
    ...mapState(['agent']),
  }
}
</script>

<style scoped>

.app-bar-logo {
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  height: 80px;
  width: 75px;
  position: absolute;
  top: 0;
  left: 0;
}
</style>